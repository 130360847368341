import React, { useState } from "react"
import styled from "styled-components"
import { useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"

import Container, { Half, Row } from "../layout/container"
import Graph from "../ui/graph"

const Section = styled.section`
  padding: 0 0 5rem;
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 0;
    .hide-phone {
      display: none;
    }
    .show-phone {
      display: block !important;
    }
  }

  .show-phone {
    display: none;
  }

  h1 {
    text-align: center;
    font-size: 70px;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 50px;
    }
  }

  h2 {
    font-size: 35px;
    line-height: 36px;
    color: #06195d;
  }
`

const Spacing = styled.div`
  margin: 5rem 0;
`

const Stats = () => {
  const [first, setFirst] = useState<any>(false)
  const [second, setSecond] = useState<any>(false)
  const [third, setThird] = useState<any>(false)

  const firstAnimation = useSpring({
    stroke: first ? "77" : "0",
    otherStroke: first ? "77, 100" : "0,100",
  })

  const secondAnimation = useSpring({
    stroke: second ? "54" : "0",
    otherStroke: second ? "54, 100" : "0,100",
  })

  const thirdAnimation = useSpring({
    stroke: third ? "86" : "0",
    otherStroke: third ? "86, 100" : "0,100",
  })

  return (
    <Section id="statistics">
      <Container>
        <h1>
          Why <em>amp</em>?
        </h1>
        <h2>
          Confidence from customers, employees and investors is harder to earn
          than ever before
        </h2>
        <Row>
          <Half>
            <p>
              In a noisy digital environment, story-telling becomes a prized
              skill. Understaffed news organisations respond best to CEOs who
              can explain the company’s story in minutes, not hours. This value
              is doubled in a time of emergency. At crunch moments like this,
              there’s no time for rehearsal: the CEO must speak from the front
              within minutes of a crisis unfolding, with a clear message of
              reassurance for customers, staff and investors.
            </p>
          </Half>
          <Half>
            <Waypoint onEnter={() => setFirst(true)} bottomOffset="25%" />
            <Graph
              stroke={firstAnimation.stroke.interpolate((x: any) =>
                Math.round(x)
              )}
              otherStroke={firstAnimation.otherStroke}
              caption="of people want CEOs to be personally visible on the future of the industry"
              source="Edelman 2019"
            />
          </Half>
        </Row>
        <Spacing>
          <Row>
            <Half>
              <p>
                A survey of millennials by Gallup found they were much more
                attracted to companies whose senior leadership could voice a
                clear vision than firms with workplace perks.
              </p>
              <p>
                Other surveys have shown that communicating a shared vision from
                the top is a key tool in acquiring the best talent and in
                retaining key staff (of all ages).
              </p>
            </Half>
            <Half>
              <Waypoint onEnter={() => setSecond(true)} bottomOffset="25%" />
              <Graph
                stroke={secondAnimation.stroke.interpolate((x: any) =>
                  Math.round(x)
                )}
                otherStroke={secondAnimation.otherStroke}
                caption="of people think it is important before accepting a job offer to check whether the CEO speaks out on controversial political and social issues"
                source="Edelman 2019"
              />
            </Half>
          </Row>
        </Spacing>
        <Row>
          <Half>
            <p>
              For high-growth companies looking to raise investment the CEO’s
              presence is critical. VCs respond to CEOs who not only have a
              great investment case but can present it with skill.
            </p>
          </Half>
          <Half>
            <Waypoint onEnter={() => setThird(true)} bottomOffset="25%" />
            <Graph
              stroke={thirdAnimation.stroke.interpolate((x: any) =>
                Math.round(x)
              )}
              otherStroke={thirdAnimation.otherStroke}
              caption="of UK and US investors say the CEO is critically important to corporate investment appeal"
              source="Korn-Ferry 2019"
            />
          </Half>
        </Row>
      </Container>
    </Section>
  )
}

export default Stats
