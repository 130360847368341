import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import Stats from "../components/sections/stats"
import Outcomes from "../components/sections/outcomes"
import Cta from "../components/ui/cta"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="CEO amp: why amp will help your brand"
        description="amp will help CEOs improve their credibility in front of key stakeholders: customers, investors and talent."
      />
      <Stats />
      <Outcomes />
      <Cta />
    </Layout>
  )
}

export default IndexPage
