import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, Third } from "../layout/container"

const Outcomes = ({ theme }: any) => {
  const Section = styled.section`
    background: ${theme.colors.light};
    padding: 5rem 0;
    font-size: 18px;
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    pre {
      font-family: ${theme.font};
      width: 100px;
      height: 100px;
      margin: 0;
      background: ${theme.colors.accent};
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: ${theme.colors.light};
      font-size: 50px;
      font-weight: bold;
      line-height: 0;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        margin: 2rem auto 0;
      }
    }

    h2 {
      color: ${theme.colors.primary};
      font-size: 60px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 30px;
      margin: 1rem 0;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        text-align: center;
      }
    }
  `

  return (
    <Section id="Outcomes">
      <Container>
        <h2>
          What <em>amp</em> will do for you
        </h2>
        <Row>
          <Third>
            <pre>1</pre>
            <h3>Boost your brand visibility</h3>
            <p>
              You might have the right products at the right price, but that’s
              irrelevant if people don’t know about your business.
            </p>
            <p>
              A strong personal brand will inspire trust from others and help a
              business grow. Achieving one will help you lead through influence
              and engagement.
            </p>
          </Third>
          <Third>
            <pre>2</pre>
            <h3>Increase media profile</h3>
            <p>
              The most effective leaders create constant content and are more
              active on social media. By sharing your expertise with
              personality, you will achieve consistent exposure for your brand
              and elevate your credibility.
            </p>
            <p>
              A human voice will hone your competitive edge in the oversaturated
              market with limitless options.
            </p>
          </Third>
          <Third>
            <pre>3</pre>
            <h3>Improve your communication skills</h3>
            <p>
              <em>
                ‘Leadership today is all about two words: It’s all about truth
                and trust’
              </em>
              . Jack Welch, ex-CEO of General Electric knows what he is talking
              about.
            </p>
            <p>
              Trust is now critical on the business agenda; <em>amp</em> will
              develop how you raise awareness of this skill to genuinely
              separate you from your competition.
            </p>
          </Third>
        </Row>
      </Container>
    </Section>
  )
}

export default withTheme(Outcomes)
