import React from "react"
import styled from "styled-components"
import { animated } from "react-spring"

const GraphWrap = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    svg {
      max-width: 200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-top: 3rem;
    flex-direction: column;
    svg {
      max-width: 50vw;
      margin: 0 auto;
    }
  }

  div {
    padding-left: 1rem;
    width: 60%;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }

  p {
    font-size: 16px;
    margin: 0;
  }
  small {
    font-size: 12px;
  }

  .wheel {
    margin: 10px auto;
  }

  .circle-bg {
    fill: none;
    stroke: ${props => props.theme.colors.primary};
    stroke-width: 0.25;
  }

  .circle {
    fill: none;
    stroke-width: 5;
    stroke: ${props => props.theme.colors.accent};
  }

  .percentage {
    font-weight: bold;
    font-size: 145px;
    line-height: 1;
    display: inline-block;
    min-width: 3em;
    &:after {
      content: "%";
      font-size: 90px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}) {
      font-size: 120px;
      width: 100%;
      &:after {
        font-size: 65px;
      }
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      text-align: center;
      font-size: 100px;
      width: 100%;
      &:after {
        font-size: 50px;
      }
    }
  }
`

const circlePath = `M18 2.5
a 15 15 0 0 1 0 30
a 15 15 0 0 1 0 -30`

interface IGraph {
  stroke: string | number
  otherStroke: string | number
  caption: string
  source?: string
}

const Graph = ({ stroke, otherStroke, caption, source }: IGraph) => (
  <GraphWrap>
    <svg viewBox="0 0 36 36" className="wheel" width="100%">
      <path className="circle-bg" d={circlePath} />
      <animated.path
        className="circle"
        strokeDasharray={otherStroke}
        d={circlePath}
      />
    </svg>
    <div>
      <animated.span className="percentage">{stroke}</animated.span>
      <p>{caption}</p>
      {source && <small>{source}</small>}
    </div>
  </GraphWrap>
)

export default Graph
